<template>
  <div>
    <div class="case-container" v-if="active === null">
      <div class="page-name">
        <div class="title">案例展示</div>
        <div class="title-bg"></div>
      </div>
      <div class="content">
        <div class="title">产品案例</div>
        <div class="list flex flex-wrap  flex-between flex-align">
          <div class="item flex flex-column flex-center flex-align" v-for="item in list" @click="openPage(item)">
            <img class="item-img" :src="item.icon" alt=""/>
            <span class="item-name">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <ModulePage v-if="active !== null"  @back="changeActiveModule"></ModulePage>
  </div>
</template>

<script>
import ModulePage from "@/views/product/components/module-page.vue";
import {mapState} from "vuex";

export default {
  components: {
    ModulePage,
  },
  data() {
    return {
      active: null,
      list: [
        {id: 1, name: "未来乡村系统", icon: require('@/assets/case/product-index1.png')},
        {id: 2, name: "政务管理系统", icon: require('@/assets/case/product-index2.png')},
        {id: 5, name: "园区公共服务平台", icon: require('@/assets/case/product-index3.png')},
        {id: 3, name: "社区服务管理系统", icon: require('@/assets/case/product-index4.png')},
        {id: 4, name: "在线考试系统", icon: require('@/assets/case/product-index5.png')},
        {id: 6, name: "智能酒店", icon: require('@/assets/case/product-index6.png')},
      ]
    }
  },
  computed: {
    ...mapState('user', ['activeID'])
  },
  watch: {
    activeID: {
      handler(newVal) {
        if (newVal) {
          sessionStorage.setItem('id', newVal)
          this.active = newVal
        } else {
          this.active = null
        }
      },
      immediate: true
    },
  },
  created() {
  },
  methods: {
    openPage(item) {
      sessionStorage.setItem('id', item.id)
      this.active = item.id
    },
    changeActiveModule(id) {
      if (id) {
        this.active = id
      } else {
        this.active = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.case-container {
  height: 1138px;
  background: url("http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/case-bg1.png") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  position: relative;
  padding: 155px 210px 0;
  text-align: left;

  .page-name {
    .title {
      font-size: 28px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 33px;
      position: relative;
      z-index: 10;
    }

    .title-bg {
      width: 93px;
      height: 10px;
      background: linear-gradient(270deg, #014DD6 0%, rgba(1, 77, 214, 0) 100%);
      border-radius: 6px 6px 6px 6px;
      margin-bottom: 47px;
      position: relative;
      z-index: 9;
      right: -30px;
      bottom: 5px;
    }
  }


  .content {
    width: 1500px;
    height: 824px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 6px 11px 4px rgba(32, 89, 192, 0.07);
    opacity: 1;
    padding: 35px 40px;
    box-sizing: border-box;

    .title {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      line-height: 26px;
      margin-bottom: 60px;
    }

    .list {
      padding: 0 40px;

      .item {
        width: 424px;
        height: 322px;
        margin-bottom: 30px;

        .item-img {
          width: 420px;
          height: 256px;
          box-shadow: 0px 6px 12px 0px rgba(33, 41, 48, 0.12);
          margin-bottom: 30px;
        }

        .item-name {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          line-height: 28px;
        }
      }
    }

  }
}
</style>
