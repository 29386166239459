<template>
  <div class="container">
    <div class="top-img flex flex-center">
      <img src="http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/product-hotel2.png" alt=""/>
    </div>
    <div class="info">
      面向酒店、民宿、公寓、养老设施，以网+云+商务为核心，提供“智慧酒店”实时高效动态信息，满足酒店需求的集成性管理系统，通过融合BIM模型、IOT技术、虚拟现实技术等，实时呈现酒店客观状态，构建“酒店数字孪生”！
    </div>
    <div class="bottom">
      <div class="title">系统优势：</div>
      <div class="list">
        <div class="item" v-for="item in list">
          <div class="name">{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        "1、充分研究宾馆酒店办公业务，为宾馆酒店量身定制的办公平台解决方案，并保留完整的电子档案。",
        "2、完善的安全机制"
      ],
    }
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  padding: 0 70px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .top-img {
    margin-bottom: 59px;

    img {
      width: 1279px;
      height: 577px;
    }
  }

  .info {
    text-indent: 2em;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    margin-bottom: 59px;

  }

  .bottom {
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
      margin-bottom: 6px;
    }

    .list {
      margin-bottom: 30px;

      .item {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;

        .name {
          font-size: 20px;
          color: #333333;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
