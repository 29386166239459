<template>
  <div class="container">
    <div class="flex">
      <img class="img1" src="~@/assets/case/park2-1.png" alt=""/>
      <div class="right flex flex-direction-column">
        <div class="right-top">目前各党支部普遍存在流动党员多、不容易集聚的现状
          ，因此需要采用党建+互联网的方式，在线上把党员连接起来，通过网上党课、答题互动等多种形式，
          规范新领域党组织的党日活动，实现各类园区党建工作灵活便捷的管理模式。
        </div>
        <div class="right-top mb50">
          菜鸟“红链e+”园区党建 APP 包含后台管理（PC端）、管理端 APP、用户端
          APP，可根据用户的政治面貌和管辖归属灵活配置菜单权限。
        </div>
        <div class="list flex flex-direction-column">
          <div class="title">
            APP 功能包含多个模块，管理员后台实现系统管理、内容维护功能。
          </div>
          <div class="item" v-for="item in list">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        '1、学习园地：可开放给所有注册用户进行在线学习，生成学习记录，并进行学习考核。',
        '2、党员之家：园区各级党支部的信息化管理功能，包括支部管理、党员管理、工作安排、活动会议、党员考评等内容。',
        '3、特色活动：园区特色活动组织、报名、活动开展，可通过图文、视频等形式生动鲜活地生成活动档案。',
        '4、先锋园地：园区各类先进评选展示，树立园区榜样。',
        '5、人才招聘：面向社会的人才招聘对接功能。',
        '6、通知公告：面向党员推送公告。',
      ]
    }
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  //padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .img1 {
    width: 310px;
    height: 537px;
    margin-right: 43px;
  }

  .right {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 33px;

    .right-top {
      text-indent: 2em;
      margin-bottom: 30px;
    }

    .mb50 {
      margin-bottom: 50px;
    }

    .list {
      .title {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .item {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
      }
    }
  }

}
</style>
