<template>
  <div class="container">
    <div class="img-list flex flex-between">
      <div class="box flex flex-direction-column">
        <img src="~@/assets/case/park1-1.png" alt=""/>
        <div class="name">网络经济服务平台</div>
      </div>
      <div class="box flex flex-direction-column">
        <img src="~@/assets/case/park1-2.png" alt=""/>
        <div class="name">网络经济服务平台</div>
      </div>
    </div>
    <div class="info">
      网络经济公共服务平台是网络经济发展局根据“十三五”规划开发的综合服务平台，立项于<span class="fw">2016</span>年，上线于
      <span class="fw">2018</span>年，目前已容纳企业 <span class="fw">300+</span>家。
    </div>
    <div class="flex flex-center bottom">
      <img class="img" src="~@/assets/case/park1-3.png" alt=""/>
      <div class="list">
        <div class="item" v-for="(item,index) in list" :class="'item' + index">
          <div :class="item.cls" class="content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {content: '为主管部门和企业搭建了 一座沟通便利的桥梁', cls: "text-right"},
        {content: '将各种服务资源有机整合'},
        {content: '为主管部门提供了一个 对外展示平台'},
        {content: '引导区内企业通过电子 商务手段转型'},
      ]
    }
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .box {
    img {
      margin-bottom: 10px;
    }

    .name {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
  }

  .img-list {
    margin-bottom: 40px;

    img {
      width: 582px;
      height: 350px;
    }
  }

  .info {
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 44px;
    margin-bottom: 50px;
    text-indent: 2em;

    .fw {
      font-weight: bold;
    }
  }

  .bottom {
    position: relative;
    margin-bottom: 50px;

    .img {
      width: 668px;
      height: 452px;
      margin-left: -10px;
    }

    .list {
      .item {
        font-size: 22px;
        font-weight: normal;
        color: #333333;
        line-height: 35px;
        position: absolute;

        .content {
          width: 247px;
          font-weight: bold;
        }
      }

      .item0 {
        left: 0;
        top: 25px;
      }

      .item1 {
        left: 0;
        bottom: 40px;
      }

      .item2 {
        right: 10px;
        top: 20px;
      }

      .item3 {
        right: 10px;
        bottom: 30px;
      }
    }

  }

}
</style>
