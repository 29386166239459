<template>
  <div class="container">
    <div class="img-list flex flex-around">
      <img src="~@/assets/case/product-country3-1.png" alt=""/>
      <img src="~@/assets/case/product-country3-2.png" alt=""/>
    </div>
    <div class="info">
      <div>
        <div>
          乡村数字化建设以省“一统三化九场景”为指导思想，内容包含文旅服务系统、
          未来产业场景信息平台系统，同时对接“2+4”的场景应用，丰富“我的家园”中的应用内容。
        </div>
        <div>
          未来乡村是一个全新命题，没有现成模式，也没有成熟经验。对于未来乡村的定义，广义上讲，
          “未来乡村”就是“美丽乡村”建设的升级版，是美丽田园、美丽乡村、美丽环境、美丽经济之上叠加的新形态；
          而从狭义上分析，“未来乡村” 是指立足乡村资源特色、地域禀赋、产业优势和人文特征等元素，
          打造特色鲜明、功能完善、产业突出、环境优美、服务便捷、智慧互联、整体智治、共同富裕的，
          引领乡村发展方向的生态、生产、生活共同体。
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="~@/assets/case/product-country3-new.png" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .box {
    img {
      margin-bottom: 10px;
    }

    .name {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
  }

  .img-list {
    padding: 0 50px;
    margin-bottom: 66px;
  }

  .info {
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    line-height: 41px;
    margin-bottom: 43px;
    text-indent: 2em;
  }

  .bottom {
    width: 1274px;
    margin-left: -20px;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}
</style>
