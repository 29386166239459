<template>
  <div class="container">
    <div class="flex flex-direction-column flex-align-center">
      <img class="img1" src="~@/assets/case/government3-1.png" alt=""/>
      <div class="info">
        工业企业亩产效益综合评价管理系统立项于2016年，上线于2017 年。
      </div>
      <img class="img2" src="~@/assets/case/government3-2.png" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  //padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .img1 {
    width: 584px;
    height: 346px;
    margin-bottom: 80px;
  }


  .info {
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    margin-bottom: 40px;
    text-align: center;
  }

  .img2 {
    width: 1284px;
    margin-bottom: 56px;
  }
}
</style>
