<template>
  <div class="container">
    <div class="flex flex-between">
      <div class="box flex flex-direction-column">
        <img class="img1" src="~@/assets/case/government1-1.png" alt=""/>
        <div class="name">企业户籍信息管理系统</div>
      </div>
      <div class="flex flex-direction-column">
        <div class="info">
          提高政务综合监管水平，为企业提供户籍管理自动化及全面整合的服务能力，解决管理分散引起的工作低效，为企业提供决策依据。
        </div>
        <img class="img2" src="~@/assets/case/government1-2.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  //padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .box {
    .name {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
  }

  .img1 {
    width: 584px;
    height: 385px;
    margin-right: 50px;
    margin-bottom: 20px;
  }

  .img2 {
    width: 446px;
    height: 446px;
    margin-left: 200px;
  }

  .info {
    width: 776px;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    text-indent: 2em;
    margin-bottom: 20px;
  }
}
</style>
