<template>
  <div class="country-container">
    <div class="page-name">
      <div class="title">案例展示</div>
      <div class="title-bg"></div>
    </div>
    <div class="content">
      <div class="content-top flex flex-between flex-align">
        <div class="content-title">{{ showCase }}</div>
        <el-button type="primary" class="back-btn" @click="backPage">返回</el-button>
      </div>
      <template v-if="!showDetail">
        <!-- 列表排列方式不同 为了偷懒 直接分成三个list写了-->
        <div class="list flex  flex-between flex-align"
             v-if="showCase === '未来乡村系统' || showCase === '政务管理系统'">
          <div class="item flex flex-column flex-center flex-align" v-for="item in list" @click="showDetailPage(item)">
            <img class="item-img" :src="item.icon" alt=""/>
            <span class="item-name">{{ item.name }}</span>
          </div>
        </div>
        <div class="list flex  flex-center flex-align"
             v-if="showCase === '社区服务管理系统' || showCase === '智能酒店'">
          <div class="item flex flex-column flex-center flex-align" v-for="item in list" @click="showDetailPage(item)">
            <img class="item-img" :src="item.icon" alt=""/>
            <span class="item-name">{{ item.name }}</span>
          </div>
        </div>
        <div class="list-padding list  flex  flex-between flex-align"
             v-if="showCase === '在线考试系统' || showCase === '园区公共服务平台'">
          <div class="item flex flex-column flex-center flex-align" v-for="item in list" @click="showDetailPage(item)">
            <img class="item-img" :src="item.icon" alt=""/>
            <span class="item-name">{{ item.name }}</span>
          </div>
        </div>
      </template>
      <template v-if="showDetail">
        <component :is="component"></component>
      </template>
    </div>
  </div>
</template>

<script>
import Country1 from "@/views/product/components/country1.vue";
import Country2 from "@/views/product/components/country2.vue";
import Country3 from "@/views/product/components/country3.vue";
import Government1 from "@/views/product/components/government1.vue";
import Government2 from "@/views/product/components/government2.vue";
import Government3 from "@/views/product/components/government3.vue";
import Park1 from "@/views/product/components/park1.vue";
import Park2 from "@/views/product/components/park2.vue";
import Server1 from "@/views/product/components/server1.vue";
import Exam1 from "@/views/product/components/exam1.vue";
import Exam2 from "@/views/product/components/exam2.vue";
import Hotel from "@/views/product/components/hotel.vue";

export default {
  name: "product",
  components: {
    Country1,
    Country2,
    Country3,
    Government1,
    Government2,
    Government3,
    Park1,
    Park2,
    Server1,
    Exam1,
    Exam2,
    Hotel
  },
  data() {
    return {
      list: [],
      showDetail: false,//是否详情页
      component: null, // 由于模板页在循环外 所以在这里定义拿来接受组件 对应的组件页面看计算属性
    };
  },
  computed: {
    showCase() {
      let id = sessionStorage.getItem('id')
      switch (id) {
        case '1': {
          this.list = [
            {id: 1, name: "智慧农文旅", icon: require('@/assets/case/product-country1.png'), components: Country1},
            {id: 2, name: "数字赋能", icon: require('@/assets/case/product-country2.png'), components: Country2},
            {id: 3, name: "乡村数字化", icon: require('@/assets/case/product-country3.png'), components: Country3},
          ]
          return '未来乡村系统'
        }
        case '2': {
          this.list = [
            {
              id: 4,
              name: "户籍式企业信息管理系统",
              icon: require('@/assets/case/product-government1.png'),
              components: Government1
            },
            {
              id: 5,
              name: "企业信息管理平台",
              icon: require('@/assets/case/product-government2.png'),
              components: Government2
            },
            {
              id: 6,
              name: "工业企业亩产效益综合评价管理系统",
              icon: require('@/assets/case/product-government3.png'),
              components: Government3
            },
          ]
          return '政务管理系统'
        }
        case '3': {
          this.list = [
            {
              id: 7, name: "社区服务管理系统", icon: require('@/assets/case/product-community1.png'),
              components: Server1
            },
          ]
          return '社区服务管理系统'
        }
        case '4': {
          this.list = [
            {
              id: 8,
              name: "浙江省国际服务外包考试系统",
              icon: require('@/assets/case/product-exam1.png'),
              components: Exam1
            },
            {
              id: 9,
              name: "浙江省电子商务职业技能考试系统",
              icon: require('@/assets/case/product-exam2.png'),
              components: Exam2
            },
          ]
          return '在线考试系统'
        }
        case '5': {
          this.list = [
            {id: 10, name: "网络经济服务平台", icon: require('@/assets/case/product-park1.png'), components: Park1},
            {
              id: 11,
              name: "菜鸟“红链e+”园区党建APP",
              icon: require('@/assets/case/product-park2.png'),
              components: Park2
            },
          ]
          return '园区公共服务平台'
        }
        case '6': {
          this.list = [
            {
              id: 12, name: "智能酒店", icon: require('@/assets/case/product-hotel1.png'),
              components: Hotel
            },
          ]
          return '智能酒店'
        }

      }
    },
  },
  methods: {
    backPage() {
      let id = sessionStorage.getItem('id')
      if (this.showDetail) {
        this.$emit('back', id)
        this.showDetail = false
      } else {
        this.$emit('back', null)
      }
    },
    showDetailPage(item) {
      this.component = item.components
      this.showDetail = true
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .back-btn {
    width: 113px;
    height: 47px;
    background: #217EFF;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 23px;
  }
}

.country-container {
  min-height: 804px;
  background: url("http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/case-bg1.png") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  position: relative;
  padding: 155px 210px 0;
  text-align: left;

  .page-name {

    .title {
      font-size: 28px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 33px;
      position: relative;
      z-index: 10;
    }

    .title-bg {
      width: 93px;
      height: 10px;
      background: linear-gradient(270deg, #014DD6 0%, rgba(1, 77, 214, 0) 100%);
      border-radius: 6px 6px 6px 6px;
      margin-bottom: 47px;
      position: relative;
      z-index: 9;
      right: -30px;
      bottom: 5px;
    }
  }

  .content {
    width: 1500px;
    margin-bottom: 60px;
    box-sizing: border-box;
    background: linear-gradient(135deg, #FFFFFF 0%, #EDF6FF 100%);
    opacity: .9;
    box-shadow: 0px 6px 11px 4px rgba(32, 89, 192, 0.07);
    padding: 35px 40px;

    .content-top {
      margin-bottom: 50px;

      .content-title {
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        line-height: 26px;
      }
    }

    .list {
      padding: 0 40px;

      .item {
        width: 424px;
        height: 322px;

        .item-img {
          width: 420px;
          height: 256px;
          box-shadow: 0px 6px 12px 0px rgba(33, 41, 48, 0.12);
          margin-bottom: 30px;
        }

        .item-name {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
      }
    }

    .list-padding {
      padding: 0 270px;
    }
  }
}
</style>
