<template>
  <div class="container">
    <div class="img-list flex flex-between">
      <div class="box flex flex-direction-column">
        <img src="~@/assets/case/case-country1.png" alt=""/>
        <div class="name">智慧农文旅</div>
      </div>
      <div class="box flex flex-direction-column">
        <img src="~@/assets/case/case-country2.png" alt=""/>
        <div class="name">智慧农文旅</div>
      </div>
    </div>
    <div class="info">
      提高政府综合监管水平，提升政府服务创新能力建立健全产业管理与服务综合体系，规范产业发展和产业升级。
      智慧化服务，以游客为中心，围绕旅游六要素-吃、住、行、游、娱、购，进行有序的整合，
      为游客提供便捷的一站式服务，全生态旅游资源，促进景区经营与区域经济更好结合，提升服务品质和景区竞争力。
    </div>
    <div class="bottom flex flex-center">
      <img src="~@/assets/case/case-country3-new.png" alt=""/>
<!--      <img src="~@/assets/case/case-country3.png" alt=""/>-->
<!--      <img src="~@/assets/case/case-country4.png" alt=""/>-->
<!--      <img src="~@/assets/case/case-country5.png" alt=""/>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .box {
    img {
      margin-bottom: 10px;
    }

    .name {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
  }

  .img-list {
    margin-bottom: 30px;

    img {
      width: 579px;
      height: 318px;
    }
  }

  .info {
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 41px;
    text-indent: 2em;
    margin-bottom: 20px;
  }

  .bottom {
    padding: 0 190px;

    img {
      width: 877px;
      height: 220px;
      border-radius: 8px;
    }
  }
}
</style>
