<template>
  <div class="container">
    <div class="flex">
      <img class="img1" src="~@/assets/case/exam1-1.png" alt=""/>
      <div class="right flex flex-direction-column">
        <div class="right-top">
          <div class="line">浙江省国际服务外包考试系统立项于 <span class="num">2013</span>年，上线于 <span class="num">2017</span>
            年。
          </div>
          <div class="line">信息技术外包服务平台：截至<span class="num"> 2023</span>年，共计培训各类学员计 <span
              class="num">40000+</span>人次。
          </div>
          <div class="line">服务外包综合平台：截至 <span class="num">2023</span> 年累计培训各类人才<span class="num">30000+</span>
            人，为<span class="num">500+</span>家企业提供国际认证培训咨询服务。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 400px;
  //padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .img1 {
    width: 584px;
    height: 423px;
    margin-right: 40px;
  }

  .right {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 46px;
    text-indent: 2em;

    .right-top {
      margin-bottom: 10px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
      margin-bottom: 12px;
      text-indent: 0;
    }

    .num {
      font-size: 24px;
      font-weight: bold;
    }


  }

}
</style>
