<template>
  <div class="container">
    <div class="info">
      浙江省电子商务职业技能考试系统立项于 <span class="num">2013</span>年，上线于<span class="num">2014</span>年。
    </div>
    <div class="top-img flex flex-around">
      <img src="~@/assets/case/exam2-3.png" alt=""/>
      <img src="~@/assets/case/exam2-4.png" alt=""/>
      <img src="~@/assets/case/exam2-5.png" alt=""/>
    </div>
    <div class="bottom">
      <div class="title">功能：</div>
      <div class="list">
        <div class="item" v-for="item in list">{{ item }}</div>
      </div>
      <div class="title mb">电商培训平台：</div>
      <div class="list">
        <div class="item">
          截至 <span class="num">2023</span> 底助力电子商务师共计培训<span class="num">30000+</span>人次<br/>
         截至 <span class="num">2023</span>年进行了<span class="num">6000+</span>人次农村电商培训，通过考核<span
            class="num">5500+</span>人。<br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        ' 1、管理子系统：采用C/S模式，需要安装客户端，供系统管理员和试卷管理员登录，能够管理账户和题库，定义试卷和管理考试，并能够进行手工评卷。',
        ' 2、考试子系统：采用B/S模式，无需安装客户端，允许考生通过IE浏览器登录系统，参加考试、查询分数、完成作业；也允许试卷管理员通过IE浏览器登录系统，手工评卷和成绩查询。'
      ]
    }
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .img-list {
    margin-bottom: 59px;

    img {
      width: 585px;
      height: 350px;
    }
  }

  .info {
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    margin-bottom: 59px;
    text-align: center;

    .num {
      font-size: 24px;
      font-weight: bold;
    }

  }

  .top {
    padding: 0 40px;
    margin-bottom: 36px;

    img {
      width: 259px;
      height: 260px;
    }
  }

  .bottom {
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
    }

    .mb {
      margin-bottom: 14px;
    }

    .list {
      margin-bottom: 30px;

      .item {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;

        .num {
          font-size: 24px;
          font-weight: bold;
        }
      }

    }
  }
}
</style>
