<template>
  <div class="container">
    <div class="img-list flex flex-between">
      <div class="box flex flex-direction-column">
        <img src="http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/government2-1.png" alt=""/>
        <div class="name">企业信息管理平台</div>
      </div>
      <div class="box flex flex-direction-column">
        <img src="http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/2ea7c3da30c1ebd42c7560c65e3c016.png" alt=""/>
        <div class="name">驾驶舱</div>
      </div>
    </div>
    <div class="info">
      <div>企业信息管理平台立项于2014 年，上线于2016 年，已容纳企业2448 家。</div>
    </div>
    <div class="bottom flex flex-center">
      <img src="~@/assets/case/government2-bottom.png" alt="">
      <div class="list">
        <div class="item" v-for="(item,index) in list" :class="`${'item' + index}`">
          <div class="title" :class="item.cls">{{ item.title }}</div>
          <div class="content" :class="item.cls">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {title: "促进", content: '促进政府主管部门企业监控的时效性和精准性', cls: 'text-right'},
        {title: "提供", content: '为主管部门对企业经济运行监测分析提供数据基础', cls: 'text-right'},
        {title: "实现", content: '实现了企业信息共享，为各级部门开展工作提供了有效的企业信息支持', cls: 'text-right'},
        {title: "提高", content: '提高了各级主管部门的服务效能，促进了政企互动'},
        {title: "提升", content: '提升了政企之间的沟通效率，同时也为企业提供了个性化、精细化的服务'},
        {title: "减轻", content: '通过系统整合，数据整合，减轻了企业数据重复申报的工作量'},
      ]
    }
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  //padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .box {
    img {
      margin-bottom: 10px;
    }

    .name {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
  }

  .img-list {
    margin-bottom: 40px;
    padding: 0 89px;

    img {
      width: 584px;
      height: 350px;
    }
  }

  .info {
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    text-align: center;
    margin-bottom: 50px;
  }

  .bottom {
    height: 800px;
    position: relative;

    img {
      width: 766px;
      height: 706px;
    }

    .list {
      .item {
        .title {
          font-size: 22px;
          color: #333333;
          line-height: 26px;
          font-weight: bold;
          margin-bottom: 4px;
        }

        .content {
          width: 293px;
          font-size: 24px;
          color: #333333;
          line-height: 36px;
        }
      }

      .item0 {
        position: absolute;
        left: 0px;
        top: 100px;
      }

      .item1 {
        position: absolute;
        left: 0;
        top: 307px;
      }

      .item2 {
        position: absolute;
        left: 0;
        top: 490px;
      }

      .item3 {
        position: absolute;
        right: 0;
        top: 100px;
      }

      .item4 {
        position: absolute;
        right: 0;
        top: 290px;
      }

      .item5 {
        position: absolute;
        right: 0;
        top: 490px;
      }

    }
  }

}
</style>
