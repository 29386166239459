<template>
  <div class="container">
    <div class="img-list flex flex-between">
      <div class="box flex flex-direction-column">
        <img src="~@/assets/case/case-country2-1.png" alt=""/>
        <div class="name">数字赋能</div>
      </div>
      <div class="box flex flex-direction-column">
        <img src="~@/assets/case/case-country2-2.png" alt=""/>
        <div class="name">数字赋能</div>
      </div>

    </div>
    <div class="title">围绕“1+3+N”的智慧社区解决方案全景图</div>
    <div class="bottom">
      <div v-for="(item,index) in list" class="item" :class="'item' + index">{{ item }}</div>
      <div class="right">
        <div class="right-list" v-for="item in list2">
          <div class="right-title">{{ item.title }}</div>
          <div class="right-item" v-for="o in item.item">{{ o }}</div>
        </div>
      </div>
    </div>
    <div class="line">
      一站式现代化综合管理平台，满足物联感知、决策指挥、应急联动、惠民服务等需求。
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: ['养老', '物业', '治理', '党建', '环境', '健康', '运营'],
      list2: [
        {title: "处理3个关系", item: ['统筹：改造更新与规划新建', '融合：城市更新与棚改推进', '联动：地上建筑与地下空间']},
        {title: "解决4大问题", item: ['安防监管困难', '社区治理人手不够', '物业管理复杂', '居民服务体验不佳']},
        {title: "释放4大红利", item: ['改善大名家 驱动大投资', ' 带动大产业 促进大转型']},
      ]
    }
  },
  methods: {},
  created() {
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 1100px;
  padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;
  position: relative;
  .line{
    position: absolute;
    bottom: 0px;
    font-size: 24px;
    color: #333;
    margin-left: 150px;
  }

  .box {
    img {
      margin-bottom: 10px;
    }

    .name {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
  }

  .img-list {
    margin-bottom: 40px;

    img {
      width: 584px;
      height: 350px;
    }
  }

  .title {
    font-size: 26px;
    font-weight: 500;
    color: #000000;
    line-height: 52px;
    text-align: center;
    margin-bottom: 80px;
  }

  .bottom {
    width: 917px;
    height: 399px;
    background: url("~@/assets/case/country2.png") no-repeat;
    background-size: cover;
    position: relative;

    .item {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      line-height: 40px;
    }

    .item0 {
      position: absolute;
      left: -7px;
      bottom: 72px;
    }

    .item1 {
      position: absolute;
      left: -50px;
      bottom: 180px;
    }

    .item2 {
      position: absolute;
      left: -12px;
      bottom: 291px;
    }

    .item3 {
      position: absolute;
      left: 186px;
      top: -43px;
    }

    .item4 {
      position: absolute;
      left: 382px;
      top: 70px;
    }

    .item5 {
      position: absolute;
      left: 425px;
      top: 178px;
    }

    .item6 {
      position: absolute;
      left: 383px;
      top: 286px;
    }

    .right {
      position: absolute;
      right: -320px;

      .right-list {
        width: 242px;
        box-sizing: border-box;
        background: #D2E5FF;
        border-radius: 8px;
        border: 1px solid #217EFF;
        padding: 12px 24px;
        margin-bottom: 14px;

        .right-title {
          font-size: 20px;
          color: #333333;
          line-height: 40px;
          font-weight: bold;
        }

        .right-item {
          font-size: 16px;
          font-weight: 500;
          color: #515151;
          line-height: 26px;
        }
      }
    }
  }


}
</style>
