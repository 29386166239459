<template>
  <div class="container">
    <div class="img-list flex flex-between">
      <div class="box flex flex-direction-column">
        <img src="http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/Server1.png" alt=""/>
        <div class="name">社区环境</div>
      </div>
      <div class="box flex flex-direction-column">
        <img src="http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/Server2.png" alt=""/>
        <div class="name">社区管理</div>
      </div>

    </div>
    <div class="info">
      社区服务管理系统实现社区管理的文档电子化和管理规范化，将现代化信息技术引入社区管理，为社区管理人员提供方便、快捷、科学、人性化的管理平台，方便社区各项工作的进行，节省宝贵的时间、人力资源。
    </div>
    <div class="bottom flex flex-around">
      <div class="flex flex-direction-column flex-align-center">
        <img src="~@/assets/case/Server3.png" alt=""/>
        <div class="title">户籍人口管理</div>
        <div class="content">对辖区内房屋进行登记：全面掌握房屋所有人，居住人员，房屋情况。</div>
      </div>
      <div class="flex flex-direction-column flex-align-center">
        <img src="~@/assets/case/Server4.png" alt=""/>
        <div class="title">党建文体</div>
        <div class="content">以党组织为核心，对党员个人及组织进行管理。包括：党员变动、党费缴纳、党组织会议、活动、培训等。
        </div>
      </div>
      <div class="flex flex-direction-column flex-align-center">
        <img src="~@/assets/case/Server5.png" alt=""/>
        <div class="title">民政保障</div>
        <div class="content">对辖区内失业人员、低保家庭、残疾人员等进行汇总统计，方便相关政策的落实及特殊家庭人员的管理。
        </div>
      </div>
    </div>
    <div class="promise flex flex-around">
      <img src="~@/assets/case/Server6.png" alt=""/>
      <img src="~@/assets/case/Server7.png" alt=""/>
      <img src="~@/assets/case/Server8.png" alt=""/>
      <img src="~@/assets/case/Server9.png" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {

  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 500px;
  padding: 0 89px;
  background: url("~@/assets/case/product-detail-bg.png") no-repeat;
  background-position-y: bottom;
  background-size: contain;

  .box {
    img {
      margin-bottom: 10px;
    }

    .name {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
  }

  .img-list {
    margin-bottom: 66px;

    img {
      width: 579px;
      height: 318px;
    }
  }

  .info {
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    margin-bottom: 140px;
    text-indent: 2em;
  }

  .bottom {
    padding: 0 80px;
    margin-bottom: 164px;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 16px;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      line-height: 23px;
      margin-bottom: 28px;
    }

    .content {
      width: 225px;
      text-indent: 2em;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
    }
  }

  .promise {
    padding: 0 40px;
    margin-bottom: 20px;

    img {
      width: 205px;
      height: 262px;
    }
  }
}
</style>
